.fullpage_wrapper {
  margin: 0;
  padding: 0;
  background: #e6e6e6;
  height: 100vh;
}

.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.add_container {
  margin-top: 22px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3px;
}

.input_container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 5px;
}

.input_container > input {
  margin-right: 20px;
}

input[type="text"],
input[type="password"] {
  padding: 10px;
  margin-bottom: 0px;
  border-radius: 5px;
  border: none;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.add_btn,
.view_btn {
  padding: 5px 25px;
  margin: 0px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #0077ff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add_btn:hover,
.view_btn:hover {
  background-color: #0044aa;
}

.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
}

.loading_animation {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #0077ff; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.passwords_container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

}

.passwords_container > h2 {
  margin-bottom: 10px;
}

.pwd_box {
  background: white;
  padding: 20px 10px;
  display: flex;
  height: 100%;
  width: 140%;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: scroll;
  max-height: 54vh; 
}

.password_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.website_info,
.password_info {
  display: flex;
  flex-direction: column;
}

.website_name,
.website_username,
.password_title {
  margin-bottom: 5px;
}

.password_field {
  display: flex;
  align-items: center;
}

.password_input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
  width: 200px;
  font-size: 1em;
  line-height: 1.5;
}

.show_password_btn {
  border: none;
  background: none;
  cursor: pointer;
  color: #0077ff;
  font-size: 1.2em;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.show_password_btn:hover {
  color: #0044aa;
}

.delete_password_btn {
  border: none;
  background: none;
  color: #dc3545;
  font-size: 1.2em;
  cursor: pointer;
  transition: color 0.3s ease;
}

.delete_password_btn:hover {
  color: #c82333;
}
.edit_password_btn {
  border: none;
  background: none;
  color: #0077ff;
  font-size: 1.2em;
  cursor: pointer;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.edit_password_btn:hover {
  color: #0044aa;
}
