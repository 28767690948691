@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* starting css */
* {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}

.home1 {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* navbar */

.header {
  display: flex;
  justify-content: space-between;
  padding: 7px;
  padding-top: 17px;
}

.header div {
  display: flex;
  align-items: center;
}

.header img {
  border-radius: 5px;
  /* padding: 2px; */
  /* margin-left: 20px; */
  width: 40px;
  /* height: auto; */
}

.header h1 {
  color: whitesmoke;
  font-family: "Kanit", sans-serif;
  font-size: 1.5rem;
}

nav ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  font-size: 1.1rem;
}

nav li {
  padding: 3px 20px;
}

nav a {
  text-decoration: none;
  color: white;
}

nav a:hover {
  text-decoration: underline;
  text-decoration-color: #009900;
  color: white;
  text-decoration-thickness: 2px;
}

.header button {
  /* padding: 4px 6px; */
  margin: 3px;
  margin-top: 0;
  background-color: #2ead2e;
  color: white;
  font-size: medium;
  /* border: none; */
  /* padding: 10px; */
  /* border-radius: 200px; */
}

.header button:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* home */

.home {
  background-image: url("img/bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.info {
  margin: 15px;
  padding: 8px;
  padding-top: 50px;
  padding-left: 50px;
  color: black;
}
.info > h1 {
  color: white;
  margin-top: 15px;
  font-size: 2.7rem;
}
.info > p {
  color: white;
  margin-top: 20px;
  margin-bottom: 30px;
}
.info > li {
  padding-top: 3px;
  list-style: none;
}

/* features */
.feature1 {
  display: flex;
  padding: 60px;
  padding-bottom: 40px;
  background-color: aliceblue;
}
.feature1 > img {
  width: 400px;
  height: auto;
  border-radius: 4px;
}
.feature1 > div {
  text-align: start;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.feature1 > h1 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.7rem;
}
.feature1 > p {
  padding-top: 10px;
  color: black;
}

/* footer */
footer > p {
  font-size: 0.9rem;
  text-align: center;
  margin: 15px;
  color: black;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  /* Home */
  .home {
    background-size: cover;
    background-position: center;
  }

  /* Header */
  .header img {
    width: 40px;
  }

  .header h1 {
    font-size: 1.2rem;
  }

  nav ul {
    font-size: 1rem;
  }

  .header button {
    font-size: small;
    padding: 8px;
  }

  /* Info */
  .info {
    padding: 10px;
    padding-top: 30px;
    padding-left: 30px;
  }

  .info > h1 {
    font-size: 2rem;
  }

  .info > p {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  /* Feature1 */
  .feature1 {
    flex-direction: column;
    padding: 30px;
  }

  .feature1 > img {
    width: 100%;
    max-width: 400px;
  }

  .feature1 > h1 {
    font-size: 1.4rem;
  }
}
